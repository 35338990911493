@import '../../styles/variables';

.footer {
	background: $darker-gray url('../../static/images/section-texture.png.webp') repeat;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 2rem $darker-gray;
	padding: 2rem;
	z-index: 999;

	.footerRow {
		align-items: center;
		justify-content: center;
		display: flex;
		flex-direction: column;

		& > * {
			flex: 1;

			@media screen and (max-width: $break-small) {
				flex: none;
			}
		}

		&.borderTop {
			border-top: 0.1rem solid $dark-gray;
			margin-top: 1rem;
			padding-top: 1rem;
		}

		@media screen and (min-width: $break-tiny) {
			flex-direction: row;
			justify-content: space-between;
		}
	}

	.logo {
		align-items: center;
		display: inline-flex;

		img {
			width: 3rem;

			@media screen and (min-width: $break-med) {
				width: 4rem;
			}

			@media screen and (min-width: $break-large) {
				width: 5rem;
			}
		}

		span {
			font-weight: 100;
			padding: 1rem;
		}
	}

	.sloganWrapper {
		align-items: center;
		display: flex;
		justify-content: center;

		.sloganText {
			font-family: Rosarivo, serif;
			font-weight: 900;
			text-align: center;

			span {
				font-family: Raleway, sans-serif;
				font-weight: 100;
			}
		}
	}

	.footerIcon {
		display: flex;

		&.copyright {
			color: $white;
			font-size: 1.2rem;
			margin-right: 0.5rem;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.attribution {
		font-size: 1.2rem;
		user-select: none;

		a {
			font-size: 1.2rem;
		}

		p {
			font-size: 1.2rem;
		}
	}

	p {
		align-items: center;
		color: $white;
		display: inline-flex;
		font-size: 1.2rem;
		margin-bottom: 0;
		transition: 0.125s font-size;

		@media screen and (min-width: $break-small) {
			font-size: 1.6rem;
		}

		@media screen and (min-width: $break-med) {
			font-size: 1.8rem;
		}
	}

	a {
		color: $light-gray;
		font-weight: 300;
		font-size: 1.2rem;
		transition: 0.125s color, font-size;

		&:hover {
			color: darken($light-gray, 25%);
			transition: color 0.125s;
		}

		@media screen and (min-width: $break-small) {
			font-size: 1.6rem;
		}

		@media screen and (min-width: $break-med) {
			font-size: 1.8rem;
		}
	}

	.footerLinks {
		align-items: center;
		display: flex;
		justify-content: end;
		user-select: none;

		li {
			align-items: center;
			display: flex;
			line-height: 3.6rem;
			font-size: 1.6rem;
			padding: 0 0.5rem;
			transition: 0.125s font-size;

			a {
				align-items: center;
				display: inline-flex;
				font-size: 1.2rem;
			}

			&.middot {
				color: $light-gray;
			}

			@media screen and (min-width: $break-small) {
				font-size: 1.8rem;
			}

			@media screen and (min-width: $break-med) {
				font-size: 2.4rem;
			}
		}
	}

	.socialLinks {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: flex-end;
		user-select: none;

		li {
			align-items: center;
			display: flex;
			line-height: 3.6rem;
			font-size: 2.4rem;
			padding: 0 0.5rem;
			transition: 0.125s font-size;

			a {
				align-items: center;
				display: inline-flex;
			}

			&.middot {
				color: $light-gray;
			}

			@media screen and (max-width: $break-med) {
				font-size: 1.6rem;
			}

			@media screen and (max-width: $break-small) {
				font-size: 1.2rem;
			}
		}
	}
}
