@import '../../styles/variables';

.modalScreenContainer {
	align-items: center;
	background-color: rgba($darker-gray, 0.9);
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 99999;

	.modalWrapper {
		background-color: $white;
		display: flex;
		height: 100%;
		width: 100%;

		.closeModalButton {
			background: none;
			border: none;
			color: $gray;
			font-size: 2rem;
			padding: 2rem;
			position: absolute;
			right: 0;
			top: 0;
			transition: 0.125s;
			z-index: 999;
			
			&:hover {
				color: $blue;
				cursor: pointer;
			}
		}

		.modalContent {
			display: flex;
			flex: 1;
			padding: 4rem;
		}

		@media screen and (min-width: $break-small){
			height: 72rem;
			width: 90rem;
		}

		@media screen and (min-width: $break-med){
			height: 72rem;
			width: 90rem;
		}

		@media screen and (min-width: $break-large) {
			height: 72rem;
			width: 90rem;
		}

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			height: 100%;
			width: 100%;
		}
	}
}
