// Page definitions

export type Page = {
  name: string,
  href: string,
  title: string,
  documentTitle: string,
  description: string,
  children: Page,
  showInMainNav: boolean,
  srcImport: Promise<any>,
  showHeader: boolean
}

interface Pages {
  [key: string]: Page
}

export const Pages = {
  home: {
    name: 'Home',
    href: '/',
    title: 'Home',
    documentTitle: 'NeuroAnimation',
    description: 'Virtual experiences to help you move beautifully.',
    showInMainNav: false,
    srcImport: () => import('./home/index.js'),
    showHeader: true
  },
  medicine: {
    name: 'Stroke',
    href: '/stroke',
    title: 'Stroke',
    documentTitle: 'NeuroAnimation | Stroke',
    description: 'State of the art therapy for stroke patients.',
    showInMainNav: true,
    srcImport: () => import('./stroke/index.js'),
    showHeader: false
  },
  brainHealth: {
    name: 'Mind-Body Health',
    href: '/mindbodyhealth',
    title: 'Mind-Body Health',
    documentTitle: 'NeuroAnimation | Mind-Body Health',
    description: 'Cognition, motor strength, mental speed, motivation, and mood.',
    showInMainNav: true,
    srcImport: () => import('./mindbodyhealth/index.js'),
    showHeader: false
  },
  entertainment: {
    name: 'Gaming',
    href: '/gaming',
    title: 'Gaming',
    documentTitle: 'NeuroAnimation | Gaming',
    description: 'A new kind of movement based gaming experience.',
    showInMainNav: true,
    srcImport: () => import('./gaming/index.js'),
    showHeader: false
  },
  clinic: {
    name: 'Clinic',
    href: '/clinic',
    title: 'Clinic',
    documentTitle: 'NeuroAnimation | Stroke Recovery Center',
    description: 'Specialized Stroke Rehab Center helping you to move beautifully again.',
    showInMainNav: true,
    srcImport: () => import('./clinic/index.js'),
    showHeader: false
  },
  science: {
    name: 'Science',
    href: '/science',
    title: 'Science',
    documentTitle: 'NeuroAnimation | Science',
    description: 'Evidence for cognitive-motor strengthening beyond standard of care.',
    showInMainNav: true,
    srcImport: () => import('./science/index.js'),
    showHeader: false
  },
  ourTeam: {
    name: 'Our Team',
    href: '/team',
    title: 'Our Team',
    documentTitle: 'NeuroAnimation | Our Team',
    description: 'Experts from academia, engineering, healthcare, and Hollywood.',
    showInMainNav: true,
    srcImport: () => import('./team/index.js'),
    showHeader: false
  },
  contact: {
    name: 'Contact',
    href: '/contact',
    title: 'Contact',
    documentTitle: 'NeuroAnimation | Contact',
    description: 'Inquiries about our therapy or our technology.',
    showInMainNav: true,
    srcImport: () => import('./contact/index.js'),
    showHeader: false
  },
  privacy: {
    name: 'Privacy',
    href: '/privacy',
    title: 'Privacy',
    documentTitle: 'NeuroAnimation | Privacy Policy',
    description: 'NeuroAnimation\'s privacy and data policy.',
    showInMainNav: false,
    srcImport: () => import('./privacy/index.js'),
    showHeader: false
  }
};


export default Pages;
