@import 'variables';

.siteWrapper {
	background-color: $darker-gray;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.container {
	margin-left: auto;
	margin-right: auto;
	max-width: 144rem;
	width: 100%;

	&.containerTight {
		max-width: 100rem;
	}

	&.centerContentVertically {
		justify-content: center;
	}

	&.centerContentHorizontally {
		align-items: center;
	}

	@media screen and (max-width: $break-large) {
		max-width: 120rem;
	}

	@media screen and (max-width: $break-med) {
		max-width: 90rem;
	}

	@media screen and (max-width: $break-small) {
		max-width: 72rem;
	}
}

.mainNav {
	align-items: center;
	background-color: rgba($darker-gray, 0.95);
	box-shadow: 0 0 0.4rem 1rem rgba($darker-gray, 0.95);
	display: flex;
	height: 5rem;
	justify-content: center;
	left: 0;
	padding: 2rem 2rem 1.2rem 2rem;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 999;

	@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
		height: 4rem;
	}

	@media screen and (max-width: $break-med) {
		justify-content: space-between;
	}
}

.mainContentWrapper {
	height: 100%;
	z-index: 499;

	&:before {
		//background-color: $white;
		content: " ";
		height: 100%;
		left: 0;
		opacity: 1;
		pointer-events: none;
		position: absolute;
		right: 0;
		transition: opacity 1s;
		width: 100%;
		z-index: 999;
	}

	&.hasLoaded {
		&:before {
			opacity: 0;
		}
	}
}
