// Loader components

import * as styles from './styles.module.scss';

const Loader = () => (
	<div class={styles.wrapper}>
		<div class={styles.icon}>
			<div />
			<div />
			<div />
			<div />
			<div />
		</div>
	</div>
);

export const SectionLoader = () => (
	<div class={styles.sectionLoader}>
		<Loader />
	</div>
);

export default Loader;
