@import '../../styles/variables';

.navLogo {
	display: flex;
	padding: 0 1rem;
	-webkit-tap-highlight-color: transparent;

	img {
		object-fit: contain;
		width: 3.8rem;

		@media screen and (max-width: $break-small) {
			width: 3.8rem;
		}

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			width: 3.8rem;
		}
	}

	.logoTitle {
		align-items: center;
		color: $white;
		display: flex;
		font-family: Raleway, sans-serif;
		font-size: 2.4rem;
		font-weight: 100;
		padding: 1rem;
	}
}