// Nav Logo component

import { useEffect, useRef } from 'preact/hooks';
import anime from 'animejs';
import logo from '../../static/images/na-logo-dark-small.png.webp';
import * as styles from './styles.module.scss';

const NavLogo = () => {
	const logoRef = useRef();
	const titleRef = useRef();

	useEffect(() => {
		if(titleRef.current) {
			titleRef.current.innerHTML = titleRef.current.innerText.replace(/\S/g, "<span class='letter'>$&</span>");
		}

		anime.timeline()
			.add({
				targets: titleRef?.current?.querySelectorAll('.letter'),
				translateX: [100,0],
				translateZ: 0,
				opacity: [0,1],
				easing: "easeOutExpo",
				duration: 1500,
				delay: (el, i) => 250 + 25 * i
			})
			.add({
				targets: logoRef.current,
				opacity: [0,1],
				easing: "easeOutExpo",
				duration: 1250,
				delay: 250,
			}, 0)
	}, []);

	return (
		<a href='/' class={styles.navLogo} title='NeuroAnimation Home Page'>
			<img ref={logoRef} alt={'NeuroAnimation Logo'} src={logo} loading='lazy' width={100} />
			{/*<span ref={titleRef} class={styles.logoTitle}>NeuroAnimation</span>*/}
		</a>
	);
}

export default NavLogo;
