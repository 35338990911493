/*
 * Context used to implement a modal
 */

import { createContext } from 'preact';
import { useEffect, useState } from 'preact/hooks';

const initialState = {
  content: null,
  isActive: false,
};

const ModalContext = createContext({
  ...initialState,
  resetModal: () => {},
  setModalContent: () => {},
});

export const ModalProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false);
  const [content, setContent] = useState(null);

  const resetModal = () => {
    setContent(initialState.content);
  };

  const setModalContent = (content) => {
    setContent(content);
  };

  useEffect(() => {
    if(content) {
      setIsActive(true);
    }
    else {
      setIsActive(false);
    }

  }, [content])

  return (
    <ModalContext.Provider
      value={{
        content,
        isActive,
        resetModal,
        setModalContent
      }}
    >
      { children }
    </ModalContext.Provider>
  )
};

export default ModalContext;
