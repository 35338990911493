@import '../../styles/variables';

.wrapper {
	align-items: center;
	background-color: $darker-gray;
	display: flex;
	justify-content: center;
	min-height: 100vh;
	padding: 2rem;
	z-index: 9999;

	.icon {
		display: flex;
		height: 10rem;
		justify-content: center;
		position: relative;
		width: 10rem;

		div {
			animation: loading 0.75s cubic-bezier(0, 0.5, 0.5, 1) infinite;
			background: $light-gray;
			display: inline-block;
			position: absolute;
			width: 0.8rem;

			&:nth-child(1) {
				animation-delay: -0.5s;
				left: 1.5rem;
			}

			&:nth-child(2) {
				animation-delay: -0.375s;
				left: 3rem;
			}

			&:nth-child(3) {
				animation-delay: -0.25s;
				left: 4.5rem;
			}

			&:nth-child(4) {
				animation-delay: -0.125s;
				left: 6rem;
			}

			&:nth-child(5) {
				animation-delay: 0s;
				left: 7.5rem;
			}
		}
	}

	@keyframes loading {
		0% {
			height: 8rem;
			top: 1rem;
		}
		50%, 100% {
			height: 4rem;
			top: 3rem;
		}
	}
}

.sectionLoader {
	box-shadow: 0 0 4rem 0 $med-dark-gray;
}
