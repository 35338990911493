// Modal component

import { useRef } from 'preact/hooks';
import { FaX } from 'react-icons/fa6';
import useModal from '../../hooks/useModal.js';
import * as styles from './styles.module.scss';

const Modal = () => {
	const {
		content,
		isActive,
		resetModal
	} = useModal();

	const containerRef = useRef();

	const check = (e)=> {
		if(e.target === containerRef.current) {
			resetModal();
		}
	}

	if(isActive) {
		return (
			<div ref={containerRef} class={styles.modalScreenContainer} onClick={check}>
				<div class={styles.modalWrapper}>
					<button class={styles.closeModalButton} onClick={resetModal}>
						<FaX />
					</button>

					<div class={styles.modalContent}>
						{ content }
					</div>
				</div>
			</div>
		);
	}

	return <></>;
}

export default Modal;
