// Site root

import { render } from 'preact';
import { AppContextProvider } from './context/AppContextProvider.tsx';
import Site from './site';

const root = document.getElementById('root');

const App = () => (
	<AppContextProvider>
		<Site />
	</AppContextProvider>
);

render(<App />, root);
