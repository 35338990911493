// Scroll Revealer component

import { useEffect, useRef, useState } from 'preact/hooks';
import anime from 'animejs';
import * as styles from './styles.module.scss';

const ScrollRevealer = ({ children, className, immediate, delay = 0, duration = 1000 }) => {
	const container = useRef();
	const [isRevealed, setIsRevealed] = useState(false);
	const [delayTimer, setDelayTimer] = useState(null);

	useEffect(() => {
		checkElementPosition();
	}, []);

	useEffect(() => {
		if(!isRevealed) {
			document.addEventListener('scroll', checkElementPosition);
		}
		else {
			document.removeEventListener('scroll', checkElementPosition);
		}

		return () => {
			document.removeEventListener('scroll', checkElementPosition);
		};
	}, [isRevealed]);

	useEffect(() => {
		if(isRevealed) {
			anime({
				targets: container.current,
				translateY: [100, 0],
				opacity: [0, 1],
				easing: "easeInOutQuad",
				duration,
				delay
			});
		}
	}, [isRevealed])

	useEffect(() => {
		if(immediate) {
			setIsRevealed(true);
		}
	}, [immediate]);

	const checkElementPosition = (e) => {
		let timer = null;

		if(delayTimer) {
			clearTimeout(delayTimer);
		}
		else {
			timer = setTimeout(() => {
				const {top, height} = container.current.getBoundingClientRect();

				if (window.innerHeight - height > top) {
					setIsRevealed(true);
				}
			}, delay);
		}

		setDelayTimer(timer);
	};

	return (
		<div ref={container} class={[styles.scrollRevealer, className].join(' ')}>
			{ children }
		</div>
	);
}

export default ScrollRevealer;
