/*
 * Utility to combine multiple context providers into a single context provider
 */

import { ModalProvider } from './ModalContext.js';

const combineComponents = (...components) => (
  components.reduce((AccumulatedComponents, CurrentComponent) => (
    ({ children }) =>
      <AccumulatedComponents>
        <CurrentComponent>
          { children }
        </CurrentComponent>
      </AccumulatedComponents>
  ),
  ({ children }) => children
));

// Context providers to be combined
const providers = [
  ModalProvider,
];

export const AppContextProvider = combineComponents(...providers);
