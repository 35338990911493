@import '../../styles/variables';


.mobileNav {
	display: none;

	.mobileNavButton {
		align-items: center;
		background-color: transparent;
		border: none;
		color: $white;
		display: flex;
		font-size: 2rem;
		justify-content: center;
		padding: 1rem;
		position: fixed;
		right: 2rem;
		top: 1rem;
		transition: 0.25s;
		-webkit-tap-highlight-color: transparent;
		z-index: 99999;

		&:hover {
			background-color: $white;
			color: $blue;
			cursor: pointer;

			@media (hover:none) {
				background-color: rgba($white, 0.25);
				color: $white;
			}
		}

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			top: 0.5rem;
		}
	}

	.mobileNavWrapper {
		background-color: rgba($darker-gray, 0.9);
		box-shadow: 0 0 1rem 0 rgba($dark-gray, 1);
		padding: 10rem 2rem 10rem 2rem;
		position: fixed;
		height: 100vh;
		min-width: 30rem;
		opacity: 0;
		right: -50rem;
		top: 0;
		transition: 0.25s ease-in-out;
		touch-action: none;
		z-index: 99998;

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			padding-top: 6rem;
		}

		&.mobileNavActive {
			opacity: 1;
			right: 0;
		}

		&.mobileNavHidden {
			opacity: 0;
			transition: 0.25s ease-in-out;
		}

		.mobileNavList {
			list-style: none;

			.navItem {
				display: flex;
				flex-direction: column;
				font-family: Nunito-Sans, sans-serif;
				font-weight: 100;
				text-align: left;

				.navLink {
					color: $white;
					padding: 1rem;
					transition: 0.25s;
					-webkit-tap-highlight-color: transparent;

					&:hover {
						font-weight: 400;
					}

					@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
						font-size: 1.8rem;
						padding: 0.5rem;
					}

					&:hover, &:active, &.active {
						color: white;
						transition: 0.125s;
					}

					&.active {
						cursor: default;
						font-weight: 700;
						text-shadow: 0.1rem 0.1rem 0.8rem rgba($darker-gray, 1);
					}
				}

				.subNav {
					padding-left: 2rem;

					.subNavItem {
						display: flex;
						flex-direction: column;
						font-family: Nunito-Sans, sans-serif;
						text-align: left;

						&:before {
							align-items: center;
							color: $white;
							content: '\2514';
							display: flex;
							font-size: 2rem;
							justify-content: center;
							height: 100%;
							left: -1rem;
							position: absolute;
							text-align: left;
							text-shadow: 0.1rem 0.1rem 0.8rem rgba($darker-gray, 1);
							width: 0.9rem;
						}

						.subNavLink {
							color: $white;
							padding: 1rem;
							-webkit-tap-highlight-color: transparent;

							&:hover, &:active, &.active {
								color: white;
								transition: 0.125s;
							}

							&:hover {
								font-weight: 400;
							}

							&.active {
								cursor: default;
								font-weight: 700;
								text-shadow: 0.1rem 0.1rem 0.8rem rgba($darker-gray, 1);
							}
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: $break-med) {
		display: block;
	}
}

.navList {
	align-items: center;
	display: flex;
	justify-content: space-evenly;
	list-style: none;

	.navItem {
		display: flex;
		justify-content: center;
		flex: 1;

		.navLink {
			color: $light-gray;
			font-family: Nunito-Sans, sans-serif;
			font-size: 1.6rem;
			font-weight: 200;
			height: 100%;
			padding: 2rem 2rem;
			text-align: center;
			text-decoration: none;
			text-shadow: 0.1rem 0.1rem 0.2rem rgba($darker-gray, 1);
			transition: 0.125s;
			user-select: none;
			-webkit-tap-highlight-color: transparent;
			white-space: nowrap;

			&::after {
				display: block;
				content: attr(data-text);
				font-weight: 400;
				height: 1px;
				color: transparent;
				overflow: hidden;
				visibility: hidden;
			}

			&:hover, &:active, &.active {
				color: white;
				transition: 0.125s;
			}

			&:hover {
				font-weight: 400;
			}

			&.active {
				cursor: default;
				font-weight: 700;
				text-shadow: 0.1rem 0.1rem 0.4rem rgba($darker-gray, 1);
			}

			@media screen and (min-width: $break-small) {
				padding: 2rem 1rem;
			}

			@media screen and (min-width: $break-large) {
				font-size: 1.6rem;
				padding: 2rem 1.2rem;
			}
		}

		.subNav {
			background-color: rgba($darker-gray, 0.95);
			box-shadow: 0 0.3rem 0.8rem 0.1rem rgba($darker-gray, 0.95);
			display: none;
			margin-top: 5.9rem;
			//top: 0;
			position: absolute;

			.subNavItem {
				display: flex;
				justify-content: center;
				flex: 1;

				.subNavLink {
					color: $light-gray;
					font-family: Nunito-Sans, sans-serif;
					font-size: 1.8rem;
					font-weight: 200;
					height: 100%;
					padding: 1.8rem 2rem;
					text-decoration: none;
					text-shadow: 0.1rem 0.1rem 0.4rem rgba($darker-gray, 1);
					text-transform: uppercase;
					transition: 0.125s;
					user-select: none;
					-webkit-tap-highlight-color: transparent;
					white-space: nowrap;

					&:hover, &:active, &.active {
						color: white;
						transition: 0.125s;
					}

					&:hover {
						font-weight: 400;
					}

					&.active {
						cursor: default;
						font-weight: 700;
						text-shadow: 0.1rem 0.1rem 0.8rem rgba($darker-gray, 1);
					}

					&::before {
						content: attr(title);
						display: block;
						height: 0;
						font-weight: 700;
						overflow: hidden;
						visibility: hidden;
					}

					@media screen and (max-width: $break-large) {
						font-weight: 300;
						padding: 2rem 2rem;
					}

					@media screen and (max-width: $break-small) {
						font-size: 1.8rem;
						min-width: 10rem;
						padding: 2rem 1rem;
					}
				}
			}
		}

		&:hover {
			.navLink:not(.active) {
				font-weight: 400;
				color: $white;
			}

			.subNav {
				display: block;
			}
		}

		&:last-child {
			.navLink {
				padding-right: 0;
			}
		}
	}

	@media screen and (max-width: $break-med) {
		display: none;
	}
}
