// Navigation component

import anime from 'animejs';
import { useEffect, useRef, useState } from 'preact/hooks';
import { Link, Match } from 'preact-router/match';
import { FaBars, FaX } from 'react-icons/fa6';
import * as styles from './styles.module.scss';

const SubNav = ({ items }) => {
	return (
		<div class={styles.subNav}>
			{
				items.map(({ name, href }) => {
					return (
						<li class={styles.subNavItem}>
							<Match path={`${href}/:page`}>
								{({ matches, path }) => (
									<Link class={[styles.subNavLink, href === path && styles.active].join(' ')} title={name} href={href} activeClassName={styles.active}>
										{name}
									</Link>
								)}
							</Match>
						</li>
					);
				})
			}
		</div>
	);
};

export const NavItem = ({ children, name, href, title }) => {
	return (
		<li class={styles.navItem}>
			{
				window.location.pathname.match(/\//g).length > 1 ? (
					<Match path={`${href}/:page`}>
						{({ matches }) => (
							<Link class={[styles.navLink, matches && styles.active].join(' ')} title={title} data-text={name} href={href} activeClassName={styles.active}>
								{name}
							</Link>
						)}
					</Match>
				) : (
					<Link class={styles.navLink} title={title} data-text={name} href={href} activeClassName={styles.active}>
						{name}
					</Link>
				)
			}

			{
				children ? <SubNav items={children} /> : null
			}
		</li>
	);
};

export const NavList = ({ navItems }) => {
	const navRef = useRef();

	useEffect(() => {
		anime({
			targets: navRef.current,
			translateY: [10,0],
			opacity: [0,1],
			easing: "easeOutExpo",
			duration: 1500,
			delay: 500
		});
	}, []);

	return (
		<ul ref={navRef} class={styles.navList}>
			{
				navItems.map(({ children, name, href, title }) => {
					return <NavItem name={name} href={href} children={children} title={title} />;
				})
			}
		</ul>
	);
};

export const MobileNav = ({ navItems }) => {
	const [isShowing, changeIsShowing] = useState(false);

	return (
		<div class={styles.mobileNav}>
			<button class={styles.mobileNavButton} title={'Mobile nav button'} onClick={() => changeIsShowing(!isShowing)}>
				{ isShowing ? <FaX /> : <FaBars /> }
			</button>

			<div class={[styles.mobileNavWrapper, isShowing ? styles.mobileNavActive : styles.mobileNavHidden].join(' ')}>
				<ul class={[styles.mobileNavList].join(' ')}>
					{
						navItems.map(({ children, name, href }) => {
							return <NavItem name={name} href={href} children={children} />;
						})
					}
				</ul>
			</div>
		</div>
	);
};

export const Navigation = ({ navItems, updateParentMobileNavState }) => {
	const mobileNavRef = useRef();

	return (
		<>
			<NavList navItems={navItems} />
			<MobileNav ref={mobileNavRef} navItems={navItems} updateParentMobileNavState={updateParentMobileNavState} />
		</>
	);
};
