@import 'variables';

.textCenter {
	text-align: center !important;
}

.textLeft {
	text-align: left !important;
}

.textRight {
	text-align: right !important;
}

.linkHighlight {
	color: $light-gray;
	transition: color 0.125s;

	&:hover {
		color: darken($light-gray, 25%);
		transition: color 0.125s;
	}
}

.listUnstyled {
	list-style: none;
}

.flexColumn {
	display: flex;
	flex-direction: column;
}

.flexRow {
	display: flex;
	flex-direction: row;
}