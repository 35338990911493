@import '../../styles/variables';

.header {
	box-shadow: inset 0 15rem 10rem -5rem $darker-gray;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-bottom: 0;
	text-align: center;
	transition: 0.25s height;

	&.hideHeader {
		height: 0% !important;
	}

	&.headerFull {
		height: 100%;

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			height: 100%;
			margin-bottom: 2rem;
		}
	}

	&.headerHalf {
		height: 95%;

		@media screen and (max-width: $break-small) {
			height: 80%;
		}

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			height: 100%;
			margin-bottom: 2rem;
		}
	}

	.videoBackgroundWrapper {
		background-color: $black;
		filter: brightness(0.75);
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 99;

		video {
			height: 100%;
			object-fit: cover;
			width: 100%;
		}

		.videoShadow {
			box-shadow: inset 0 15rem 10rem -5rem $darker-gray;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	.headerContent {
		align-items: center;
		display: flex;
		height: 100%;
		left: 0;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 100;
	}

	.headerTitleWrapper {
		padding: 2rem;
		width: 90rem;

		h1 {
			color: white;
			font-family: FiraSans-ExtraBold, sans-serif;
			font-size: 18rem;
			font-weight: 900;
			text-shadow: 0.1rem 0.1rem 0.8rem rgba($darker-gray, 1);
			transition: 0.25s font-size;
			line-height: 18rem;
			text-align: left;
			z-index: 999;

			@media screen and (max-width: $break-large) {
				font-size: 15rem;
				line-height: 15rem;
			}

			@media screen and (max-width: $break-med) {
				font-size: 12rem;
				line-height: 12rem;
			}

			@media screen and (max-width: $break-small) {
				font-size: 10rem;
				line-height: 10rem;
			}
		}

		@media screen and (max-width: $break-med) {
			width: 70rem;
		}

		@media screen and (max-width: $break-small) {
			width: 40rem;
		}
	}

	.headerTitle {
		color: white;
		font-family: Raleway, sans-serif;
		font-size: 4.8rem;
		font-weight: 100;
		text-shadow: 0.1rem 0.1rem 0.8rem rgba($darker-gray, 1);
		transition: 0.25s font-size;

		@media screen and (min-width: $break-tiny) {
			font-size: 5.4rem;
		}

		@media screen and (min-width: $break-small) {
			font-size: 7.2rem;
		}

		@media screen and (min-width: $break-med) {
			font-size: 9.0rem;
		}

		@media screen and (min-width: $break-large) {
			font-size: 12.0rem;
		}

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			font-size: 5.0rem;
		}
	}

	.headerSubTitle {
		color: white;
		font-size: 2.8rem;
		font-family: Rosarivo, sans-serif;
		font-weight: 900;
		margin-bottom: 1.2rem;
		text-align: center;
		text-shadow: 1.2rem 0.1rem 1.8rem rgba($darker-gray, 1);

		@media screen and (min-width: $break-tiny) {
			font-size: 3.6rem;
		}

		@media screen and (min-width: $break-small) {
			font-size: 4.0rem;
		}

		@media screen and (min-width: $break-med) {
			font-size: 4.8rem;
		}

		@media screen and (min-width: $break-large) {
			font-size: 6.0rem;
			margin-bottom: 2.4rem;
		}

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			font-size: 2.2rem;
		}

		b {
			text-shadow: 0.1rem 0.1rem 1rem rgba($darker-gray, 1);

			@media screen and (max-width: $break-small) {
				font-weight: 700;
				text-shadow: 0.1rem 0.1rem 0.2rem rgba($darker-gray, 1);
			}

			@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
				font-weight: 100;
				text-shadow: 0.1rem 0.1rem 0.2rem rgba($darker-gray, 1);
			}
		}
	}

	.headerTagline {
		color: white;
		font-family: Raleway, sans-serif;
		font-size: 1.2rem;
		font-weight: 200;
		margin-bottom: 0;
		text-align: center;
		text-shadow: 0.1rem 0.1rem 0.8rem rgba($darker-gray, 1);

		@media screen and (min-width: $break-tiny) {
			font-size: 1.4rem;
		}

		@media screen and (min-width: $break-small) {
			font-size: 1.6rem;
		}

		@media screen and (min-width: $break-med) {
			font-size: 1.8rem;
		}

		@media screen and (min-width: $break-large) {
			font-size: 2.0rem;
		}

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			font-size: 1.8rem;
		}
	}

	.scrollButtonContainer {
		align-items: center;
		bottom: 5rem;
		display: none;
		justify-content: center;
		position: absolute;
		width: 100%;
		z-index: 999;

		.scrollButton {
			align-items: center;
			animation: pulse-animation 3s infinite;
			background-color: rgba($white, 0.5);
			border: none;
			border-radius: 100%;
			box-shadow: 0 0 1rem 1rem $darker-gray;
			color: $dark-gray;
			display: flex;
			justify-content: center;
			font-size: 3rem;
			opacity: 0;
			padding: 1rem;
			transition: 0.25s;

			@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
				font-size: 1.4rem;
			}

			&:hover {
				cursor: pointer;
				background-color: rgba($white, 0.9);
			}
		}

		@media screen and (min-width: $break-small) {
			display: flex;
		}

		@media only screen and (max-height: $break-mobile-height-landscape) and (orientation: landscape) {
			bottom: 2rem;
		}
	}

	@supports (-webkit-touch-callout: none) {
		background-attachment: scroll;
	}
}

@keyframes pulse-animation {
	0% {
		box-shadow: 0 0 0 0 rgba($darker-gray, 0.2);
		scale: 1;
	}
	5% {
		scale: 0.96;
	}
	15% {
		scale: 1.04;
	}
	30% {
		scale: 1;
	}
	100% {
		box-shadow: 0 0 0 2rem rgba($darker-gray, 0);
	}
}
